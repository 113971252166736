/**
 * Created by Andrey Popov on 4/4/25.
 */

var ScrollTable = function (dataSource, options) {
    cleverapps.EventEmitter.call(this);

    this.minIconsCount = options.minIconsCount;
    this.addToStart = options.addToStart;

    this.dataSource = dataSource;

    this.rows = this.dataSource.getData();

    this.dataSource.on("add", this.addRow.bind(this), this);
    this.dataSource.on("remove", this.removeRow.bind(this), this);
    this.dataSource.on("reorder", this.reorder.bind(this), this);
};

ScrollTable.prototype = Object.create(cleverapps.EventEmitter.prototype);
ScrollTable.prototype.constructor = ScrollTable;

ScrollTable.prototype.getRows = function () {
    return this.rows;
};

ScrollTable.prototype.addRow = function (newRow) {
    var insertIndex = this.addToStart ? 0 : this.rows.length;

    for (var i = insertIndex; i < this.rows.length; i++) {
        this.trigger("move", i, i + 1);
    }

    this.rows.splice(insertIndex, 0, newRow);
    this.trigger("add", newRow, insertIndex);
};

ScrollTable.prototype.removeRow = function (rowId) {
    var row = this.rows.find(function (r) {
        return r.id === rowId;
    });
    var toRemoveIndex = this.rows.indexOf(row);

    this.rows.forEach(function (row, i) {
        if (i > toRemoveIndex) {
            this.trigger("move", i, i - 1);
        }
    }.bind(this));
    this.rows.splice(toRemoveIndex, 1);

    this.trigger("remove", toRemoveIndex);
};

ScrollTable.prototype.reorder = function () {
    var rows = this.dataSource.getData();
    for (var actualIndex = 0; actualIndex < rows.length; actualIndex++) {
        var tableRow = this.rows.find(function (r) {
            return r.id === rows[actualIndex].id;
        });

        if (tableRow) {
            var tableIndex = this.rows.indexOf(tableRow);
            if (tableIndex !== actualIndex) {
                this.trigger("swap", tableIndex, actualIndex);
            }
        }
    }

    this.rows = rows;
};

ScrollTable.prototype.destructor = function () {
    runCleaners(this);

    this.dataSource.destructor();
};